<template>
  <div class="ground-cameras">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/Ground Cameras.png" />
        <span>Ground Cameras</span>
      </div>

      <el-button
        type="info"
        @click="$router.push({ path: '/data', query: { tabsIndex: 1, time } })"
      >
        Show More
      </el-button>
    </div>

    <el-image
      class="ground-cameras__img"
      :src="
        detail.latelyGroundPhotoList && detail.latelyGroundPhotoList[0]
          ? detail.latelyGroundPhotoList[0].filePath
          : ''
      "
      :preview-src-list="[
        detail.latelyGroundPhotoList && detail.latelyGroundPhotoList[0]
          ? detail.latelyGroundPhotoList[0].filePath
          : ''
      ]"
      fit="cover"
    />
  </div>
</template>

<script>
import { props } from './props'

export default {
  props,
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.ground-cameras {
  &__img {
    width: 100%;
    height: calc(100% - 36px - 20px);
    border-radius: 10px;
  }
}
</style>
